.elevation-1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}
.elevation-2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}
.elevation-3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}
.elevation-4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}