.cdk-overlay-container {
  .mat-mdc-menu-panel {
    .mat-mdc-menu-item.selected {
      font-weight: bold;
    }

    &.button-drop-down {
     .mat-mdc-menu-item-text {
      font-weight: 700;
      display: flex;
      flex-direction: column;
      padding: 4px 0;
      .caption {
        font-size: 14px;
        font-weight: 300;
      }
     }
    }
  }
}