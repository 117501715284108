@mixin mat-table {
  table {
    thead tr th {
      &.mdc-data-table__header-cell {
       font-weight: 600;
       line-height: 24px;
       font-size: 16px;
      }
    }
    tbody tr td{
      &.mdc-data-table__cell {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

}

@include mat-table;