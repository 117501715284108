@use '@angular/material' as mat;
@use '../colours.scss' as colours;

$typography1: mat.m2-define-typography-config(
  $font-family: 'Poppins',
  $headline-5: mat.m2-define-typography-level(32px, 38px, 600), /* H1 */
  $headline-6: mat.m2-define-typography-level(24px, 32px, 600), /* H2 */
  $subtitle-1: mat.m2-define-typography-level(20px, 32px, 600), /* H3 */
  $subtitle-2: mat.m2-define-typography-level(18px, 32px, 400), /* H4 */
  $body-1: mat.m2-define-typography-level(16px, 24px, 400),
  $body-2: mat.m2-define-typography-level(16px, 24px, 600),
  $caption: mat.m2-define-typography-level(13px, 13px, 500), /* error texts and hints */
  $button: mat.m2-define-typography-level(16px, 24px, 600)
);

$mobile-typography: mat.m2-define-typography-config(
  $font-family: 'Poppins',
  $headline-5: mat.m2-define-typography-level(24px, 28px, 600), /* H1 */
  $headline-6: mat.m2-define-typography-level(18px, 24px, 600), /* H2 */
  $subtitle-1: mat.m2-define-typography-level(16px, 20px, 600), /* H3 */
  $subtitle-2: mat.m2-define-typography-level(14px, 16px, 400), /* H4 */
  $body-1: mat.m2-define-typography-level(16px, 24px, 400),
  $body-2: mat.m2-define-typography-level(16px, 24px, 600),
  $caption: mat.m2-define-typography-level(13px, 13px, 500), /* error texts and hints */
  $button: mat.m2-define-typography-level(16px, 24px, 600)
);

@mixin typograhpy() {

  @media screen and (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
    @include mat.all-component-typographies($mobile-typography);
  }
  @media screen and (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    @include mat.all-component-typographies($typography1);
  }
  @media screen and (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @include mat.all-component-typographies($typography1);
  }

  .mat-typography {
    color: colours.$dark-primary-text;
  }
  a {
    color: colours.$dark-primary-text;
    font-weight: 600;
    &.disabled {
      pointer-events: none;
      opacity: .6;
    }
  }
  h1, h2, h3, h4, span, div, p {
    &[color="accent"] {
      color: colours.get-color-from-palette(colours.$apollo-accent-palette, 500)
    }
    &[color="primary"] {
      color: colours.get-color-from-palette(colours.$apollo-primary-palette, 500)
    }
    &[color="tertiary"] {
      color: colours.get-color-from-palette(colours.$apollo-tertiary-palette, 500)
    }
    &[color="error"] {
      color: colours.get-color-from-palette(colours.$apollo-red-palette, 500)
    }
  }

  .content-body {
    p:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}

// Mat fonts doesn't support keep_off/unpin icon at this moment
mat-icon {
  position: relative;
  &.unpin:before {
    content: '';
    position: absolute;
    width: 24px;
    left: 0px;
    top: 10px;
    background-color: #fff;
    box-shadow: 0 1px 1px 1px #FFF;
    height: 1px;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
  }
}

// common colour classes
.bg-neutral-500 {
  background-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 500) !important;
}
.text-neutral-500 {
  color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 500) !important;
}
.text-accent-500 {
  color: colours.get-color-from-palette(colours.$apollo-accent-palette, 500) !important;
}
.text-primary-500 {
  color: colours.get-color-from-palette(colours.$apollo-primary-palette, 500) !important;
}
.text-warn-500 {
  color: colours.get-color-from-palette(colours.$apollo-red-palette, 500) !important;
}
.bg-primary-50 {
  background-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 50) !important;
}
.bg-primary-100 {
  background-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 100) !important;
}
.bg-primary-400 {
  background-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 400) !important;
}
.bg-primary-500 {
  background-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 500) !important;
}
.bg-accent-50 {
  background-color: colours.get-color-from-palette(colours.$apollo-accent-palette, 50) !important;
}
.bg-accent-100 {
  background-color: colours.get-color-from-palette(colours.$apollo-accent-palette, 100) !important;
}
.bg-neutral-50 {
  background-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 50) !important;
}
.bg-neutral-500 {
  background-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 500) !important;
}
.border-primary-100 {
  border-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 100)
}
.border-neuteral-100 {
  border-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 100)
}
