@use '../colours.scss' as colours;
@use '../helpers/responsive.scss' as responsive;

@mixin dialog-base($dark) {
  width: 50% !important;
  max-width: 640px;
  background-color: #fff;


  @include responsive.mobile {
    width: 100% !important;
    max-width: 100% !important;
  }
  @if ($dark) {
    & {
      color: #fff;
    }
    .close mat-icon {
      color: #fff;
    }
  } @else {
    .close mat-icon {
      color: #000;
    }
  }
  [mat-dialog-header] {
    padding: 16px 24px;
    font-weight: 400;
    font-size: 20px;
    @include responsive.mobile {
      padding: 16px;
    }
    @if ($dark) {
      & {
        background-color: colours.get-color-from-palette(
          colours.$apollo-neutral-palette,
          500
        );
      }
    }
    h2 {
      margin: 0;
    }
    .close-button {
      button {
        margin-right: -8px;
        font-weight: 300;
        text-transform: uppercase;
        @if ($dark) {
          color: #fff;
        } @else {
          color: #000;
        }
      }
    }
  }
  [mat-dialog-content] {
    padding:  24px;
    padding-bottom: 0;
    text-align: left;
    color: colours.$dark-primary-text;
  }
  [mat-dialog-actions] {
    justify-content :initial;
    padding:  24px;
  }
}

.cdk-overlay-container {
  .apollo-backdrop {
    background-color: #fff;
    opacity: 0.75 !important;
  }

  mat-dialog-container {
    .mat-mdc-dialog-surface {
      position: relative;
      background-color: transparent;
      @include responsive.mobile {
        border-radius: 0;
      }
      .close {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
      }
      .controls {
        padding-bottom: 1;
      }
    }
  }

  .select-category-dialog,
  .media-search-dialog {
    max-width: initial !important;
    @apply w-full h-full sm:h-5/6;
    background-color: #fff;
    [mat-dialog-content] {
      padding-top: 0 !important;
    }
  }

  .default-dialog {
    @include dialog-base(false);
  }

  .default-dark-dialog {
    @include dialog-base(true);
  }
}