@use '../../../assets/theming/colours.scss' as colours;


$icon-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 200);

:root {
  --mdc-shape-small: 8px;

  input[type=password] {
    letter-spacing: 1.5px;
  }

  mat-label + mat-form-field {
    margin-top: 8px;
  }
  mat-form-field {

    mat-icon {
      color: $icon-color
    }
    &.mat-form-field-invalid {
      mat-icon {
        color: red;
      }
    }
  }
}