.page-loader {
  position: relative;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 12px;
    font-weight: 500;
    animation-duration: 1s;
    animation-name: opacity;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0;
    transform: translate3d(-50%, -50%, 0);
  }
}



@keyframes opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}