@use '../colours.scss' as colours;
form {
  label,
  mat-label {
    &.required::after {
      content: '*';
      margin-left: 4px;
      color: colours.get-color-from-palette(colours.$apollo-tertiary-palette, 500);
    }
  }
}