.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}


:root {
  --col-1: calc(100vw/12);
  --col-2: calc(100vw/6);
  --col-3: calc(100vw/4);
  --col-4: calc(100vw/3);
  --col-5: calc(100vw/12*5);
  --col-6: calc(100vw/2);
}