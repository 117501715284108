@use '../colours.scss' as colours;
@use "sass:math";


$app-container-padding: 24px;
@mixin admin-app-container {
  // add a generic set of rules to each child page in the router

  &.is-mobile::ng-deep router-outlet + * {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex: 1 1 100%;
    padding: 16px;
    padding-bottom: 40px; // offset for top navbar in mobile mode
  }
  &:not(.is-mobile)::ng-deep router-outlet + * {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex: 1 1 100%;
    padding-bottom: 40px; // offset for top navbar in desktop mode
    padding: 24px;

    > .header, header[role=page-header] {
      @include adminHeader;
    }
  }
}

@mixin app-container {
  // add a generic set of rules to each child page in the router

  &.is-responsive::ng-deep > router-outlet + * {
    // margin-top: 75px;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex: 1 1 100%;
    padding: 16px;
    // padding-bottom: 40px; // offset for top navbar in mobile mode

  }

  &:not(.is-responsive)::ng-deep > router-outlet + * {
    // margin-top: 75px;
    -webkit-overflow-scrolling: touch;
    padding: 24px;

    width: 100%;
    flex-direction: column !important;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    > * {
      max-width: 1000px;
      width: 100%;
    }

    header.admin {
      @include adminHeader;
    }

  }
}

.scroll-wrapper {
  display: flex;
  position:relative;
  height: 100%;
  overflow-y: auto;
  width: 100%;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.app-container {
  max-width: 1000px;
  width: 100%;
}

.container-border-top {
  border-top: 1px solid colours.get-color-from-palette(colours.$apollo-neutral-palette, 100);
}

@mixin adminHeader {
  background-color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 75);
  border-bottom: 1px solid colours.get-color-from-palette(colours.$apollo-neutral-palette, 100);
  padding: $app-container-padding;
  margin: -$app-container-padding;
  margin-bottom: 0;
  h1 {
    margin: 24px 0 0 0;
  }
  nav {
    background-color: #fff;
    padding: math.div($app-container-padding, 2);
    margin: -$app-container-padding;
    margin-top: 24px;
    border-top: 1px solid colours.get-color-from-palette(colours.$apollo-neutral-palette, 100);
  }
}